<template>
    <page-section class="grey lighten-4">
        <page-section-title title="お知らせ"></page-section-title>
        <news-card
            date="2022/9/20"
            tag="event"
            title="第3回 AI・人工知能EXPO【秋】のブース出展に参加いたします"
            body="2022年10月26日（水）〜28日（金）に東京ビッグサイトで開催される第6回 AI・人工知能EXPO【秋】において、
                  ㈱知能フレームワーク研究所ブース（小間番号：13-20）内にて、Tuttiの展示・デモを行います。是非お越しください。
                  （イベント公式ページは<a href='https://www.nextech-week.jp/autumn/ja-jp/about/ai.html' target='_blank'>こちら</a>）"
        >
        </news-card>
        <news-card
            date="2022/5/10"
            tag="event"
            title="第6回 AI・人工知能EXPO【春】のブース出展に参加いたします"
            body="2022年5月11日（水）〜13日（金）に東京ビッグサイトで開催される第6回 AI・人工知能EXPO【春】において、
                  弊社パートナー企業である株式会社エキュメノポリスのブース内にて、弊社プロダクトである
                  Tutti、Scattの展示・説明を行います。是非お越しください。
                  （イベント公式ページは<a href='https://www.nextech-week.jp/spring/ja-jp/about/ai.html' target='_blank'>こちら</a>）"
        >
        </news-card>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'
import NewsCard from './NewsCard.vue'

export default {
  components: { PageSection, PageSectionTitle, NewsCard },
}
</script>
<style scoped>
img.logo {
    width: 70%;
}
</style>
