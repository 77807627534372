<template>
    <page-section>
        <page-section-title
            title="お問い合わせ"
            subtitle="Tuttiのツールとしてのご利用はもちろん、<br>
                      カスタムでのラベリングシステムの開発や、データラベル収集の運用なども広く承っております。<br>
                      まずはお気軽にご相談ください。"
        ></page-section-title>
        <v-card id="form" class="ma-auto">
            <div :style="styles.form">
                <p class="form-header" :style="styles.formHeader">お名前 <span class="red--text">*</span></p>
                <v-text-field
                    v-model="input.name"
                    :dense="!$vuetify.breakpoint.forPC"
                    outlined
                    :style="styles.formTextInput"
                    placeholder="情報 太郎"
                ></v-text-field>
                <p class="form-header" :style="styles.formHeader">貴社名 <span class="red--text">*</span></p>
                <v-text-field
                    v-model="input.affiliation"
                    :dense="!$vuetify.breakpoint.forPC"
                    outlined
                    :style="styles.formTextInput"
                    placeholder="株式会社知能フレームワーク研究所"
                ></v-text-field>
                <p class="form-header" :style="styles.formHeader">メールアドレス <span class="red--text">*</span></p>
                <v-text-field
                    v-model="input.email"
                    :dense="!$vuetify.breakpoint.forPC"
                    outlined
                    :style="styles.formTextInput"
                    placeholder="info@tutti.ai"
                ></v-text-field>
                <p class="form-header" :style="styles.formHeader">お問い合わせ内容 <span class="red--text">*</span></p>
                <v-textarea
                    v-model="input.message"
                    :dense="!$vuetify.breakpoint.forPC"
                    outlined
                    :style="styles.formTextInput"
                    placeholder="ご利用のお問い合わせ、製品に関してのご質問などのご記入をお願いいたします。"
                    height="250"
                ></v-textarea>

                <v-btn
                    rounded
                    x-large
                    width="200"
                    color="black"
                    style="display:block"
                    class="font-weight-bold mx-auto my-4"
                    @click="submitForm()"
                    :dark="isSubmittable"
                    :disabled="!isSubmittable"
                >送信する</v-btn>
            </div>
        </v-card>
    </page-section>
</template>
<script>
import PageSection from './PageSection.vue'
import PageSectionTitle from '@/components/PageSectionTitle'
import axios from 'axios'

export default {
    components: { PageSection, PageSectionTitle },
    data: () => ({
        input: {
            name: '',
            affiliation: '',
            email: '',
            message: ''
        }
    }),
    computed: {
        styles() {
            return {
                form: {
                    padding: this.$vuetify.breakpoint.forPC ? '32px 48px' : '16px 14px'
                },
                formHeader: {
                    marginBottom: this.$vuetify.breakpoint.forPC ? '16px' : '4px'
                },
                formTextInput: {
                    fontSize: this.$vuetify.breakpoint.forPC ? undefined : '14px'
                },
            }
        },
        isSubmittable() {
            return this.input.name !== ''
                && this.input.affiliation !== ''
                && this.input.email !== ''
                && this.input.message !== '';
        }
    },
    methods: {
        submitForm() {
            axios.post('/ducts/api/v1/email/send', this.input).then((response) => {
                const res = response.data;
                if(res.storage === 'success' && res.email === 'success') {
                    window.location.href = '/thanks';
                } else {
                    alert('お問い合わせメールの送信に失敗しました。大変お手数ですが、info@tutti.aiまで直接メールをお送りいただけますと幸いです。');
                }
            })
        }
    }
}
</script>
<style scoped>
#form {
    width: 935px;
}
.form-header {
    font-weight: bold;
}
</style>
